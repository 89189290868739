// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

/**
 * CONSTS
 */
 export const BASE_URL = 'http://localhost';

 export const API_PORT = '9002';
 export const AUTH_PORT = '8002';
 export const NOTIFS_PORT = '8003';
 export const ANG_PORT = '4201';
 export const PDF_PORT = '8010';
 
 export const environment = {
   production: true,
   API_ENDPOINT: `https://api.knockknock.mt/`,//`${BASE_URL}:${API_PORT}`,
   API_GRAPHQL: `https://api.knockknock.mt/api/graphql`,
 
   // API_GRAPHQL: `${BASE_URL}:${API_PORT}/api/graphql`, 
 
   AUTHORITY_ENDPOINT: `https://auth.knockknock.mt`,
    
   NOTIFICATIONS_API_ENDPOINT: 'https://notifications.knockknock.mt/notification', 
   NOTIFICATIONS_API: `https://notifications.knockknock.mt/api/notifications`,

  //  NOTIFICATIONS_API: `${BASE_URL}:${NOTIFS_PORT}/notifications`, 
  //  NOTIFICATIONS_API_ENDPOINT: `${BASE_URL}:${NOTIFS_PORT}/notification`, 
  //  AUTHORITY_ENDPOINT: `${BASE_URL}:${AUTH_PORT}`,
 
   POST_LOGOUT_REDIRECT_URI: `https://knockknock.mt`,
   REDIRECT_URI: `https://knockknock.mt`,
   SILENT_REDIRECT_URI: `https://knockknock.mt/silent`,
 
   SCOPE: 'openid profile app knockknock.public',
 
   AUTOMATIC_SILENT_RENEW: true,

   CDN_ENDPOINT: `https://cdn.knockknock.mt/`
 };
 
 /*
  * For easier debugging in development mode, you can import the following file
  * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
  *
  * This import should be commented out in production mode because it will have a negative impact
  * on performance if an error is thrown.
  */
 // import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
 