import { Subject, Subscription } from "rxjs";
import { Component, OnDestroy, OnInit } from "@angular/core";

export interface ILifetimeManagement {
  subscriptions$ : Subscription[];
}

@Component({template:''})
export abstract class LifetimeManagement implements ILifetimeManagement {
  
  ngOnInit(): void {
  }

  public readonly onDestroy$ = new Subject<void>();
  
  ngOnDestroy(): void {
    this.subscriptions$.map(elem => {
      if(elem) {
        elem.unsubscribe();
      }
    });

    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
  
  private __subscriptions : Subscription[] = [];

  get subscriptions$(): Subscription[] {
    return this.__subscriptions;
  }

  constructor() { }

}
