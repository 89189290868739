import { Agent } from "@app/data/models/agent.model";
import { Broker } from "@app/data/models/broker.model";
import { Client } from "@app/data/models/client.model";
import { Owner } from "@app/data/models/owner.model";

export class FullAccount {
    documents: any;
    subscriptionActive: boolean;
	constructor(
		public subjectId: string = null,
		public id: string = null,
		public code: string = null,
		public firstName: string = null,
    public lastName: string = null,
    public fullName : string = null,
		public email: string = null,

    public client: Client = null,
    public agent: Agent = null,
    public broker: Broker = null,
    public owner: Owner = null,

    public accountTypeCode: string = null,   
    
		) { }
}

export class Session {
  public IS_CONNECTED = this.session === 'ONLINE';
  
  constructor(
    public session: string = 'OFFLINE',
    public account: FullAccount) { }
}
