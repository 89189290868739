import { Injectable, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { MetaProperty } from 'typescript';
 
@Injectable({
   providedIn: 'root'
})
export class SEOService { 
 
  constructor(@Inject(DOCUMENT) private dom) { 
  }
    
  updateCanonicalUrl(url:string){
    const head = this.dom.getElementsByTagName('head')[0];
    var element: HTMLLinkElement= this.dom.querySelector(`link[rel='canonical']`) || null
    if (element==null) {
      element= this.dom.createElement('link') as HTMLLinkElement;
      head.appendChild(element);
    }
    element.setAttribute('rel','canonical')
    element.setAttribute('href',url)
  }

  updateMetaField(content:string, type:string){
    const head = this.dom.getElementsByTagName('head')[0];
    var element: HTMLMetaElement = this.dom.querySelector("meta[name='"+ type +"']") || null
    if (element==null) {
      element= this.dom.createElement('meta') as HTMLMetaElement;
      head.appendChild(element);
    }
    element.setAttribute('name',type)
    element.setAttribute('content',content)
  }

  updateOGMetaField(content:string, type:string){
    const head = this.dom.getElementsByTagName('head')[0];
    var element: HTMLMetaElement = this.dom.querySelector("meta[property='"+ type +"']") || null
    if (element==null) {
      element= this.dom.createElement('meta') as HTMLMetaElement;
      head.appendChild(element);
    }
    element.setAttribute('property',type)
    element.setAttribute('content',content)
  }
} 