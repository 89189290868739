import { BehaviorSubject, from, Observable, of } from "rxjs";
import { Injectable } from "@angular/core";
import { AuthenticationConstants } from "./authentication-constants";
// import { AuthService } from "../services/auth.service";
import { AccountFullByTokenQuery } from "../auth/account-full-by-token.query";
import { map, switchMap } from "rxjs/operators";
import { FullAccount, Session } from "../models/full-account.model";

@Injectable({
  providedIn: 'root',
})
export class  AuthenticationModeHelper {
  // public isDeveloper: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public isAuthenticated: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false); //JSON.parse(localStorage.getItem(DeveloperConstants.IS_DEVELOPER_MODE)));
  constructor(private accountQuery : AccountFullByTokenQuery) {
    // _auth.mgr.events.addUserLoaded((e) => {
    //   if(_auth.currentUser.profile && _auth.currentUser.profile.role) {
    //     
    //     if(_auth.currentUser.profile.role === 'Developer') {
    //       console.log(`Developer Mode: Set to ${true}`);
    //       this.isDeveloper.next(true);
    //     }
    //   }
    // });
    // let permissions = JSON.parse(localStorage.getItem("permissions"));
    // 
    // if(permissions) {
    // var foundRole = permissions.find(_=>_ === "Developer");
    // if(foundRole) {
    //   this.isDeveloper.next(true);
    // }
    // }
    
  }
  next(val: boolean) {
    localStorage.setItem(AuthenticationConstants.IS_AUTHENTICATION_MODULE_AVAILABLE, JSON.stringify(val));
    ////debugger;
    this.isAuthenticated.next(val);
  }

  account() : Observable<Session> {
    // ////debugger;
    // ////debugger;
   
    var acc$ = this.accountQuery   
      .fetch()
      .pipe(map(result => {
        var item = (<any>result.data);
        var fullAccount = (<any>item).accountFullByToken.data as FullAccount;
        var session = new Session('ONLINE', fullAccount);
        return session;
      }));

    return this.isAuthenticated.pipe(
      map((isAuth) => {
          return isAuth;
      }),
      switchMap((isAuth : boolean) => {

        if(isAuth) {
          return acc$;
        } else {
          return of(new Session('OFFLINE', null))
        }
      })
    );


            
    // if(this.isAuthenticated.getValue() === true) {
      
    // var acc$ = this.accountQuery
   
    //   .fetch()
    //   .pipe(map(result => {

    //     var item = (<any>result.data);
    //     var fullAccount = (<any>item).accountFullByToken.data as FullAccount;

    //     var session = new Session('ONLINE', fullAccount);

    //     return session;
    //   }));



    //   var auth$ = this.isAuthenticated.pipe(
        
    //     switchMap((_:boolean)=> {
          
    //       if(_) {
    //         return acc$;
    //       } else {
    //         return of(new Session('OFFLINE', null))
    //       }
    //     }));
      

    //   return auth$;

    // }
    //  else {
    //    var offlineSession = new Session('OFFLINE', null);
       
    //    return of(offlineSession);
    //  }
  }
}
