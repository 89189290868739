import {Injectable} from '@angular/core';
import {Query} from 'apollo-angular';
import gql from 'graphql-tag';

@Injectable({
  providedIn: 'root',
})
export class AccountFullByTokenQuery extends Query {
  document = ACCOUNT_FULL_BY_TOKEN;
}

const ACCOUNT_FULL_BY_TOKEN =  
gql`
query {
    accountFullByToken { 
    data { 
      id 
      subjectId 
      email 
      firstName 
      lastName
      accountTypeCode  
      address {
        id
        line1
        postCode
        locality {
          id
          code
          name
        }
      }
      owner {
          id
          phoneNumber
          aboutMe
          subjectId
          profileImage {
            id
            url
          }
          address {
            id
            line1
            street
            postCode
            locality {
              id
              code
              name
            }
          }
          languages {
            id
            code
            name
          }
        }
      
      client {
          id
          phoneNumber
          name
          surname
          aboutMe
          profileImage {
            id
            url
          }
          address {
            id
            line1
            street
            postCode
            locality {
              id
              code
              name
            }
          }
          languages {
            id
            code
            name
          }
        }

        broker {
          id
          phoneNumber
          name 
          surname
          aboutMe
          profileImage {
            id
            url
          }
          address {
            id
            line1
            street
            postCode
            locality {
              id
              code
              name
            }
          }
          languages {
            id
            code
            name
          }
        }
      
      agent {
          id
        code
        phoneNumber
        aboutMe
        surname
        name
        yearsOfExperience
        profileImage {
          id
          url
        }
        languages {
          id
          code
          name
        }
        nationalities {
          code
          name
        }
        licenseNo
        favoriteLocalities {
          code 
          name
        }
        documents {
          id
          mediaType {
            code
            name
          }
        }
        subscriptionActive
        subscriptionExpireAt
        status {
          code
          name
        }
        address {
          line1
          street
          postCode
          locality {
            id
            code
            name
            region {
              code
              name
              country {
                code
                name
              }
            }
          }
        }
      }
    } 
  } 
}`;
