
    <h1 id="waiting">Waiting...</h1>
    <div id="error"></div>
    
    <!--<script src="../../assets/oidc-client.min.js"></script>
    <script>
        //////debugger
        Log.logger = console;
        //////debugger
        new UserManager().signinRedirectCallback().then(function (user) {
            if (user == null) {
                document.getElementById("waiting").style.display = "none";
                document.getElementById("error").innerText = "No sign-in request pending.";
            }
            else {
                window.location = "/";
            }
        })
        .catch(function (er) {
            document.getElementById("waiting").style.display = "none";
            document.getElementById("error").innerText = er.message;
        });
    </script>-->
