import { ModuleWithProviders, NgModule } from "@angular/core";
import { SignalRService } from "./_services/signal-r.service";

@NgModule({
  declarations: [],
  imports: [],  
  providers: [],
  entryComponents: []
})
export class SignalRModule {

  static forRoot(): ModuleWithProviders<SignalRModule> {
    return {
      ngModule: SignalRModule,
      providers: [
        SignalRService 
      ]
    };
  }
}