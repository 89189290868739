
import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';



import { Observable } from 'rxjs';


import { AppSettings } from '@isb-technologies/app.settings';
import { RequestMessage } from './request-message.model';

@Injectable()
export class ApiService {
  private endPoint: string = `${AppSettings.API_ENDPOINT}`;

  constructor(
    private _http: HttpClient
  ) { }

  public get<T>(url: string): Observable<T> {
    return this._http.get<T>(`${this.endPoint}${url}`).pipe(map(
      (results: T) => {
        return results;
      }
    ));
  }

  public post<T, U = T>(url: string, object: RequestMessage<U>) {
    return this._http.post<RequestMessage<U>>(`${this.endPoint}${url}`, object);
  }

  public _post<T>(url: string, object: T, options): Observable<ArrayBuffer> {
    let http = this._http.post(`${this.endPoint}${url}`, object, options);
    return http;
  }

  public postWithoutRequestMessage<T, U = T>(url: string, object: T): Observable<U> {
    return this._http.post<U>(`${this.endPoint}${url}`, object);
  }

  public postFile(url: string, file: File, objectId: string): Observable<object> {
    var formData = new FormData();
    formData.append('file', file);
    formData.append('id', objectId);
    return this._http.post<object>(`${this.endPoint}${url}`, formData);
  }
}
