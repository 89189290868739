import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser, Location } from '@angular/common';
import { ActivatedRouteSnapshot, Router } from '@angular/router';

// import { UserManager, Log, MetadataService, User } from 'oidc-client';
import { map, tap } from 'rxjs/operators';
import { NgxPermissionsService } from 'ngx-permissions';
// import { CookieService } from 'ng2-cookies';
import { DeveloperConstants } from '@isb-technologies/framework/developer/developer-constants';
import { DeveloperModeHelper } from '@isb-technologies/framework/developer/developer-mode.helper';
import { Profile } from '../models/profile.model';
//import { AuthService } from '../services/auth.service';
import { ProfileService } from '../services/profile.service';
import { AuthenticationModeHelper } from '../developer/authentication-mode.helper';
import { ProfileBySubjectIdGQL } from './profile-by-subject-id.query';

import { OidcClientNotification, OidcSecurityService } from 'angular-auth-oidc-client';
import { AuthyService } from './authy.service';


@Component({
  selector: 'app-auth',
  providers: [],
  templateUrl: 'auth.component.html',
  styleUrls: ['auth.component.scss']
})
export class AuthComponent implements OnInit {
  //, private location:Location
  cookies: Object;

  isBrowser: boolean = false;

  constructor(private location: Location,
    //private service: AuthService,
    // private cookieService: CookieService, 
    private router: Router,
    // private _profileService: ProfileService,
    // private _permissions: NgxPermissionsService,
    private _dev: DeveloperModeHelper,
    private _auth: AuthenticationModeHelper,
    private _ngxPermissions: NgxPermissionsService,
    private _profileQueryBySubjectId: ProfileBySubjectIdGQL,
    @Inject(PLATFORM_ID) private platformId: any,
    public oidcSecurityService: OidcSecurityService,
    public _authy: AuthyService,
    // public snapshot: ActivatedRouteSnapshot,


    // private _script: ScriptLoaderService,
    // private _payslipService: PayslipService
    // route: ActivatedRouteSnapshot, state: RouterStateSnapshot
  ) {
    this.isBrowser = isPlatformBrowser(this.platformId);
  }

  ngOnInit() {

    this._authy.checkAuth().subscribe((isAuthenticated) => {
      ////debugger; 
      console.log('is authenticated', isAuthenticated)
    });

    // let url = this.snapshot.toString();
    
  // this.oidcSecurityService./authorize(),
    // this.authy.checkAuth().subscribe((isAuthenticated) => {
    //   ////debugger; 
    //   console.log('is authenticated', isAuthenticated)
    // });

    // this._script.loadScripts('body', ['assets/vendors/base/vendors.bundle.js', 'assets/demo/default/base/scripts.bundle.js'], true)
    // .then(result => {
    //   Helpers.setLoading(false);
    //    });

              // let redirectUrl = "/";

              // //  redirectUrl = localStorage.getItem('postSuccessfulLoginRedirectUrl')


              // this.oidcSecurityService.checkAuth()
              // .pipe(
              //   tap(({isAuthenticated}) => {
              //     if(isAuthenticated) {
              //       this.router.navigateByUrl(redirectUrl);
              //     }
              //   }
              // ))
              // .subscribe(({isAuthenticated}) => {console.log('is authenticated', isAuthenticated)

              //     ////debugger;
           
              //   });

    // this.oidcSecurityService.authorize();

    // Log.logger = console;
    // var self = this;

    // ////debugger;
    // this.oidcSecurityService.isAuthenticated$.subscribe(({isAuthenticated}) => {console.log('is authenticated', isAuthenticated)
    // ////debugger;
    //  let redirectUrl = localStorage.getItem('postSuccessfulLoginRedirectUrl')
    // this.router.navigateByUrl(redirectUrl);
  // });

    // self.router.navigateByUrl("/cookie-policy");
  // });
//     ////debugger;
//           let redirectUrl = "/";

//           if(isAuthenticated) {
            
//               this._auth.next(true);
//               redirectUrl = localStorage.getItem('postSuccessfulLoginRedirectUrl')
//               self.router.navigateByUrl(redirectUrl);
            
//           }
        
//         // if (this.isBrowser) {
    
//         // }
  // });

//             // let redirectUrl = localStorage.getItem('postSuccessfulLoginRedirectUrl')

    
// this.oidcSecurityService.userData$.subscribe( (u) => {

//   localStorage.setItem('USER', JSON.stringify(u));

//   self.router.navigateByUrl('cookie-policy');
//   // this.oidcSecurityService.setStorage(cookieStorage);


//   if(u) {
//     ////debugger;
//     this._auth.next(true);

//     // self.router.navigateByUrl(redirectUrl);
//   }
//   ////debugger;
// });

    // this.oidcSecurityService.checkAuth().subscribe((auth) => {
    //       if (!auth) {
    //     document.getElementById("waiting").style.display = "none";
    //     document.getElementById("error").innerText = "No sign-in request pending.";

    //     // this._auth.next(false);
    //   }
    //   else {   
    //     // self.service.currentUser = user;
    //     // self.service.userLoadededEvent.emit(user);
        
    //     let redirectUrl = "/";
        
    //     if (this.isBrowser) {
    //       redirectUrl = sessionStorage.getItem('postSuccessfulLoginRedirectUrl')
    //     }
        
    //     // Compute check if Profile exists for the first time here.
    //     // var authenticatedUser = user;
    //     var userProfile = authenticatedUser.profile;
    //     var subjectId = userProfile.sub;
            
    //     if (subjectId) {          
    //       self.onProfileCheck(subjectId, redirectUrl);

    //     } else {          
    //       self.router.navigateByUrl(redirectUrl);
    //     }
       
    //   }
    // });
   

    // self.service.mgr.signinRedirectCallback().then(function (user: User) {
    //   if (user == null) {
    //     document.getElementById("waiting").style.display = "none";
    //     document.getElementById("error").innerText = "No sign-in request pending.";

    //     // this._auth.next(false);
    //   }
    //   else {   
    //     self.service.currentUser = user;
    //     self.service.userLoadededEvent.emit(user);
        
    //     let redirectUrl = "/";
        
    //     if (this.isBrowser) {
    //       redirectUrl = sessionStorage.getItem('postSuccessfulLoginRedirectUrl')
    //     }
        
    //     // Compute check if Profile exists for the first time here.
    //     var authenticatedUser = user;
    //     var userProfile = authenticatedUser.profile;
    //     var subjectId = userProfile.sub;
            
    //     if (subjectId) {
    //       // self.router.navigate(['/profile', `${subjectId}`,'authorise']);

    //       ////debugger
    //       self.onProfileCheck(subjectId, redirectUrl);

    //     } else {
    //       ////debugger
    //       self.router.navigateByUrl(redirectUrl);
    //     }
       
    //   }
    // })
    //   .catch(function (er) {
    //     console.log(er);
    //     console.log(er.message);

    //     // application not working page - error page - check metronic.
    //     self.router.navigate(['/auth-error']);

    //     // document.getElementById("waiting").style.display = "none";
    //     // document.getElementById("error").innerText = er.message;
    //   });
  }

  onProfileCheck(subjectId: string, redirectUrl: string) {
    // this._loadingService.showLoading('Please wait...');

    ////debugger
    let vars = {
      subjectId: subjectId,
      assignedOnly: true
    };

    this._profileQueryBySubjectId
      // .watch(vars)
      // .valueChanges
      .fetch(vars)
      .pipe(map(result => {
      
        var item = (<any>result.data);
        return (<any>item).accountProfileBySubjectId.data as Profile;
      })
      ).subscribe((item: any) => {
        //this.item = item as Profile;
        
        if (item.id) {


          var userRole = item.roles;
          var permissions = [];
          ////debugger
          if(userRole)
                  if(userRole[0]) {
                    console.log(userRole[0]);
                    if(userRole[0].permissions && userRole[0].assigned)
                    // if(userRole[0].appRole.appRolePermissions) {
                      userRole[0].permissions.map((elem, i) => {
                        if(elem.assigned === true) {
                          permissions.push(elem.name);
                        }
                        }) 
                    // }
                  }


                  // let identityRole = '';
                  // if(identityRole = this.service.currentUser.profile.role) {
                  //   this._dev.isDeveloper.next(true);
                  //   permissions.push(identityRole);
                  // } else {
                  //   this._dev.isDeveloper.next(false);
                  //   localStorage.removeItem(DeveloperConstants.IS_DEVELOPER_MODE);
                  // }

                  

                  localStorage.setItem("permissions", JSON.stringify(permissions));
                  this._ngxPermissions.loadPermissions(permissions);
          
          this.router.navigateByUrl(redirectUrl);
        } else {
          this.router.navigate(['/profile', `${subjectId}`, 'authorise']);
          // this._loadingService.hideLoading();
        }
      });
    // let vars = {
    //   subjectId: subjectId
    // };

    // this._profileService.getProfileBySubjectIdQuery(subjectId)
    //   .pipe(map(result => {
      
    //     var item = (<any>result);
    //     return (<any>item) as Profile;
    //   })
    //   ).subscribe((item: any) => {
    //     //this.item = item as Profile;
        
    //     if (item.id) {
    //       // this.service.appProf = item;
    //       this.service.setApplicationProfileUser(item);

    //       var userRole = item.appUserRoles;
    //       var permissions = [];
    //       if(userRole)
    //               if(userRole[0]) {
    //                 if(userRole[0].appRole) 
    //                 if(userRole[0].appRole.appRolePermissions) {
    //                   userRole[0].appRole.appRolePermissions.map((elem, i) => {
    //                       permissions.push(elem.appPermission.claimValue);
    //                     }) 
    //                 }
    //               }

    //               let identityRole = 'Developer';
    //               if(identityRole = this.service.currentUser.profile.role) {
    //                 this._dev.isDeveloper.next(true);
    //                 //localStorage.setItem(DeveloperConstants.IS_DEVELOPER_MODE, JSON.stringify(false));
    //                 permissions.push(identityRole);
    //               } else {
    //                 this._dev.isDeveloper.next(false);
    //                 localStorage.removeItem(DeveloperConstants.IS_DEVELOPER_MODE);
    //                 // localStorage.setItem(DeveloperConstants.IS_DEVELOPER_MODE, JSON.stringify(false));
    //               }

    //               localStorage.setItem("permissions", JSON.stringify(permissions));
    //              this._permissions.loadPermissions(permissions);
          
    //       this.router.navigateByUrl(redirectUrl);
    //     } else {
    //       this.router.navigate(['/profile', `${subjectId}`, 'authorise']);
    //       // this._loadingService.hideLoading();
    //     }
    //   });
  }
}