export class SignalRConstants {
  /** Session Management */
  public static readonly SESSION_STARTED = "SessionStarted";
  public static readonly SESSION_ENDING = "SessionEnding";

  public static readonly AUTH_ID_TEST = "AuthenticationIdTest";

  /** ENDOF Session Management */

  /** Notifications */
  public static readonly NOTIFICATION = "Notification";   
  /** ENDOF Notifications */

}