

// import { Injectable } from "@angular/core";
// import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from "@angular/router";
// import { UserService } from "../_services/user.service";
// import { Observable } from "rxjs/Rx";

// @Injectable()
// export class AuthGuard implements CanActivate {

//     constructor(private _router: Router, private _userService: UserService) {
//     }

//     canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
//         let currentUser = JSON.parse(localStorage.getItem('currentUser'));
//         return this._userService.verify().map(
//             data => {
//                 if (data !== null) {
//                     // logged in so return true
//                     return true;
//                 }
//                 // error when verify so redirect to login page with the return url
//                 this._router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
//                 return false;
//             },
//             error => {
//                 // error when verify so redirect to login page with the return url
//                 this._router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
//                 return false;
//             });
//     }
// }


import { Inject, Injectable, PLATFORM_ID} from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

// import { User } from 'oidc-client';
// import { AuthService } from '../services/auth.service';
import { isPlatformBrowser } from '@angular/common';

import { LoginResponse, OidcClientNotification, OidcSecurityService } from 'angular-auth-oidc-client';

import { map } from 'rxjs/operators';


@Injectable()
export class AuthGuard implements CanActivate {

    // private user: User;

    isBrowser: boolean = false;
    
    constructor(
      @Inject(PLATFORM_ID) private platformId: any,
         // private authService: AuthService,
         public oidcSecurityService: OidcSecurityService)

      {
        this.isBrowser = isPlatformBrowser(this.platformId);
       }


      
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        // let loggedIn = false;
        // const claims = route.data;

        // Hot or Cold Observables - Make it hot!
        // loggedIn = this.authService.loggedIn;
       
        const self = this;
        // if (this.isBrowser) {
          localStorage.setItem("postSuccessfulLoginRedirectUrl", state.url);
        // } 


        // var loggedRequest = this.oidcSecurityService.checkAuth()
        // .pipe(
        //    map((_ : LoginResponse) => {
        //      ////debugger;
        //     var isAuthenticated =  _.isAuthenticated;

        //     return isAuthenticated;
        //   })
        // );

        // return loggedRequest;

        return true;

        // return this.authService.isUserLoggedIn().pipe(map(res => {
          
        //   return res;
        // }));
    }
}
