
import {map, tap} from 'rxjs/operators';
// import { Injectable } from "@angular/core";
// import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from "@angular/router";
// import { UserService } from "../_services/user.service";
// import { Observable } from "rxjs/Rx";

// @Injectable()
// export class AuthGuard implements CanActivate {

//     constructor(private _router: Router, private _userService: UserService) {
//     }

//     canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
//         let currentUser = JSON.parse(localStorage.getItem('currentUser'));
//         return this._userService.verify().map(
//             data => {
//                 if (data !== null) {
//                     // logged in so return true
//                     return true;
//                 }
//                 // error when verify so redirect to login page with the return url
//                 this._router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
//                 return false;
//             },
//             error => {
//                 // error when verify so redirect to login page with the return url
//                 this._router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
//                 return false;
//             });
//     }
// }


import { Inject, Injectable, PLATFORM_ID} from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, UrlTree } from '@angular/router';

// import { User } from 'oidc-client';
// import { AuthService } from '../services/auth.service';
import { from } from 'rxjs';
import { isPlatformBrowser } from '@angular/common';

import { AuthenticatedResult, LoginResponse, OidcClientNotification, OidcSecurityService } from 'angular-auth-oidc-client';
import { AuthenticationModeHelper } from '../developer/authentication-mode.helper';
import { AuthyService } from '../auth/authy.service';


@Injectable()
export class AuthRequiredGuard implements CanActivate {

    // private user: User;

    isBrowser: boolean = false;

    constructor(
      // private authService: AuthService, 
      private router: Router,
      @Inject(PLATFORM_ID) private platformId: any,
      public _auth: AuthenticationModeHelper,
      public oidcSecurityService: OidcSecurityService,
      public authy: AuthyService,
      ) { 
        this.isBrowser = isPlatformBrowser(this.platformId);
      }

      canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {//: Observable<boolean | UrlTree> {
        // return this.oidcSecurityService.isAuthenticated$.pipe(
          return this.authy.isAuthenticated$.pipe(
          map((_loginResponse) => {
            // allow navigation if authenticated
            if (_loginResponse) {
              return true;
            }
            // redirect if not authenticated

            localStorage.setItem('postSuccessfulLoginRedirectUrl', state.url);
            
            return this.router.parseUrl('/unauthorized');
          })
        );
      }
    }
  

    // canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    //     // let loggedIn = false;
    //     // const claims = route.data;

    //     ////debugger;
    //     // const self = this;

    //     ////debugger
    //     // if (isPlatformBrowser(this.platformId)) {
    //       localStorage.setItem("postSuccessfulLoginRedirectUrl", state.url);

          
    //     // // }

    //     // // var loggedRequest = this.authService.isUserLoggedIn()
    //     // // .pipe(
    //     // //   tap(_ => {
    //     // //     ////debugger
            
    //     // //     if(_ === false) {
    //     // //       this.router.navigate(['unauthorized']);   
    //     // //     }
    //     // //   })
    //     // // );

    //     // // return loggedRequest;

    //     // let s = false;

    //     // var loggedRequest = 
    //     // // this.oidcSecurityService.checkAuth()
    //     // // this.oidcSecurityService.checkAuth()//.subscribe(() => /* ... */);

    //     // // this.oidcSecurityService.isAuthenticated$
    //     // this.authy.isAuthenticated$
    //     // .pipe(
    //     //   //  tap( ({isAuthenticated, userData})=> {
    //     //   //         ////debugger;

    //     //   //         // this._auth.next(isAuthenticated);

    //     //   //   // if(isAuthenticated === false) {
    //     //   //   //   this.router.navigate(['unauthorized']);   
    //     //   //   // }
    //     //   // }),
    //     //   map((auth_) => {
    //     //     return auth_;
    //     //   })
    //     // ).subscribe(_=> {
    //     //   s = _;
    //     //   ////debugger;
    //     // })
    //     // // ).pipe(
    //     // //   map((_)=> {

    //     // //     if(_ === false) {
    //     // //     this.oidcSecurityService.
    //     // //       return true;
    //     // //     }
    //     // //   })
    //     // // )

    //     // // return loggedRequest;

    //     // ////debugger;
    //     // return s;
    // }


