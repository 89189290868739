import { NgDompurifySanitizer } from "@tinkoff/ng-dompurify";
import { TuiRootModule, TuiDialogModule, TuiNotificationsModule, TUI_SANITIZER, TuiAlertModule } from "@taiga-ui/core";
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpConfigInterceptor } from './http/interceptor/httpconfig.interceptor';
import { Api } from './http/Api';
import { AppRoutingModule } from './app-routing.module';
import { ServicesModuleModule } from './services-module.module';
import { AgmCoreModule } from '@agm/core';
import { AppComponent } from './app.component';
import { BrowserTransferStateModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

/** Apollo Angular */
import {APOLLO_OPTIONS} from 'apollo-angular';
import {HttpLink} from 'apollo-angular/http';
import {InMemoryCache} from '@apollo/client/core';
import { AppSettings } from '@isb-technologies/app.settings';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { SEOService } from './pages/rem/property/seo.service';
import { SharedTitleService } from "@isb-technologies/framework/services/shared-title.service";
import { DeveloperModeHelper } from "@isb-technologies/framework/developer/developer-mode.helper";
import { AuthenticationModule } from "@isb-technologies/authentication/authentication.module";
import { AuthModule, LogLevel, OidcConfigService } from 'angular-auth-oidc-client';
import { CustomStorage } from "src/storage/custom-storage";
import { AuthenticationModeHelper } from "@isb-technologies/authentication/developer/authentication-mode.helper";
import { AuthyService } from "@isb-technologies/authentication/auth/authy.service";
import { SignalRModule } from "@isb-technologies/signalr/signalr.module";
import { LoadingService } from "./services/loading/loading.service";

/** ENDOF - Apollo Angular */

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    HttpClientModule,
    BrowserTransferStateModule,
    ServicesModuleModule,
    BrowserAnimationsModule,
    NgbModule,
    TuiRootModule,
    TuiDialogModule,
    TuiNotificationsModule,
    
    AuthenticationModule.forRoot(),
    AuthModule.forRoot({
      config: {
        authority: AppSettings.AUTHORITY_ENDPOINT,
        redirectUrl: AppSettings.REDIRECT_URI,
        postLogoutRedirectUri: AppSettings.POST_LOGOUT_REDIRECT_URI,
        clientId: 'knockknockpublic',
        autoUserInfo: true,
        scope: AppSettings.SCOPE,
        responseType: 'id_token token',
        silentRenew: true,
        silentRenewUrl: AppSettings.SILENT_REDIRECT_URI,
        logLevel: LogLevel.Error,
        storage: new CustomStorage()
      }
    }),
    SignalRModule.forRoot(),
    AppRoutingModule,
    TuiAlertModule,
    AgmCoreModule.forRoot({
    apiKey: 'AIzaSyBfXuSXUMJ7bfRiZ7on6sVJKm5e8o7Jns4',
    libraries: ['places']
  })],
  providers: [
    LoadingService,
    OidcConfigService,
    AuthyService,
    SharedTitleService,
    DeveloperModeHelper,
    Api, 
    AuthenticationModeHelper,
    { provide: HTTP_INTERCEPTORS, useClass: HttpConfigInterceptor, multi: true },
    {
      provide: APOLLO_OPTIONS,
      useFactory: (httpLink: HttpLink) => {
        return {
          cache: new InMemoryCache({
            addTypename: false
          }),
          link: httpLink.create({
            uri: `${AppSettings.API_GRAPHQL}`,
          }),
        };
      },
      deps: [HttpLink],
    },
    SEOService,
      {provide: TUI_SANITIZER, useClass: NgDompurifySanitizer}
],
  bootstrap: [AppComponent]
})
export class AppModule { }
