import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthComponent } from './auth/auth.component';
// import { AuthorisationComponent } from './authorisation/authorisation.component';
import { SilentComponent } from './silent/silent.component';
import { UnauthorizedComponent } from './unauthorized';

const routes: Routes = [  
  {
    path: 'auth',
    component: AuthComponent
  },
  {
    path: 'silent',
    component: SilentComponent
  },
  {
    path: 'unauthorized',
    component: UnauthorizedComponent
  },
  // {
  //   path: 'authorise',
  //   component: AuthorisationComponent
  // },  
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AuthenticationRoutingModule {}
