import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Router, NavigationStart, NavigationEnd, NavigationError, NavigationCancel, RoutesRecognized } from "@angular/router";
// import { AuthService } from '../services/auth.service';

import { OidcClientNotification, OidcSecurityService } from 'angular-auth-oidc-client';


@Component({
  selector: 'app-unauthorized',
  providers: [],
  templateUrl: 'unauthorized.component.html',
  styleUrls: ['unauthorized.component.scss']
})
export class UnauthorizedComponent implements OnInit {
  //, private location:Location
  constructor(
    private location:Location, 
    // private service: AuthService,
    public oidcSecurityService: OidcSecurityService,
     public router: Router)  {

  }

  ngOnInit() {
    this.login();
  }

  login() {
     // var previousUrl;// : [Event, Event];
    // this.service.startSigninMainWindow();
    this.oidcSecurityService.authorize();

  }

  goback() {
    // this.location.back();
  }
}
