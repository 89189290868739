

import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';

// import { User } from 'oidc-client';
// import { AuthService } from '../services/auth.service';
import { from, of, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { AuthenticatedResult, LoginResponse, OidcClientNotification, OidcSecurityService, UserDataResult } from 'angular-auth-oidc-client';
import { AuthenticationModeHelper } from '../developer/authentication-mode.helper';
import { isPlatformBrowser } from '@angular/common';
import { AuthyService } from '../auth/authy.service';

@Injectable()
export class EnhancedAuthGuard implements CanActivate {

  // private user: User;

  isBrowser: boolean = false;

  constructor(
    // private authService: AuthService,
    public oidcSecurityService: OidcSecurityService,
    public _auth: AuthenticationModeHelper,
    @Inject(PLATFORM_ID) private platformId: any,
    public authy: AuthyService,
    private router: Router) {
    this.isBrowser = isPlatformBrowser(this.platformId);
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {//: Observable<boolean | UrlTree> {
    // return this.oidcSecurityService.isAuthenticated$.pipe(
      return this.authy.isAuthenticated$.pipe(
      
      map((_loginResponse) => {
        // allow navigation if authenticated
        // if (_loginResponse) {
        this._auth.next(_loginResponse);
        return true;
        // }
        // redirect if not authenticated
        // return this.router.parseUrl('/unauthorized');
      }),
      
    );
  }
}

//   canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
//     // var loggedRequest = this.authService.isUserLoggedIn()

    
//     // var loggedRequest = this.oidcSecurityService.isAuthenticated$
//     return true;
//     // var loggedRequest = this.authy.isAuthenticated$
//     // .pipe(
//     //    map((_) => {

//     //     // var isAuthenticated =  _.isAuthenticated;

//     //     ////debugger;
//     //     // return isAuthenticated;
//     //     // this._auth.next(_.isAuthenticated);
//     //     this._auth.next(_);
//     //     return true;
//     //   })
//     // );

//     // // return of(true);

//     // return loggedRequest;

// //     ////debugger;
// //     // if (this.isBrowser) {
// //       // let init_ = JSON.parse(localStorage.getItem('USER')) as UserDataResult;
// //       // var loggedRequest = of(init_)//this.oidcSecurityService.checkAuth()
// //       .pipe(
// //          tap(_=> {
// // //                 ////debugger;
// // // if(_.isAuthenticated) {

// // //                 this._auth.next(_.isAuthenticated);

// //             // let init_ = JSON.parse(localStorage.getItem('USER')) as UserDataResult;

            

            
// // // }
        
// //         }),
// //         map((auth_) => {
// //           ////debugger;
// //           return true;
// //         })
// //       );
    
// //       // .subscribe(({isAuthenticated})=> {
// //       //     return isAuthenticated;
// //       // });
       

// //       return loggedRequest;
//     // } else {
//     //   return of(false);
//     // }
//   }

// }
