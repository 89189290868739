<tui-root>

<!-- <div class="container" [class.ssr-not-loaded]="!isBrowser">
  <div class="row">
    <div class="col-12">
      <router-outlet></router-outlet>
    </div>
  </div>
</div> -->

<!-- <app-splash-screen></app-splash-screen> -->
<router-outlet [class.ssr-not-loaded]="!isBrowser"></router-outlet>

<!-- <ngx-spinner
  bdColor="rgb(0,0,0)"
  size="medium"
  color="#fff"  
  type = "square-jelly-box" [fullScreen] = "true">
</ngx-spinner> -->


<!-- type="ball-scale-multiple" -->
  <!-- <p style="font-size: 20px; color: white">Loading...</p> -->
</tui-root>

<!-- <router-outlet *ngIf="!isBrowser" [class.ssr-not-loaded]="isBrowser"></router-outlet> -->