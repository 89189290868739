import { environment } from '@app/../environments/environment';


export class AppSettings {
    // public static readonly PRINTER_API = environment.PRINTER_API;
    public static readonly API_ENDPOINT = environment.API_ENDPOINT;

    public static readonly CDN_ENDPOINT = environment.CDN_ENDPOINT;

    public static readonly API_GRAPHQL = environment.API_GRAPHQL;

    public static readonly NOTIFICATIONS_API = environment.NOTIFICATIONS_API;

    public static readonly NOTIFICATIONS_API_ENDPOINT = environment.NOTIFICATIONS_API_ENDPOINT;

    public static readonly AUTHORITY_ENDPOINT = environment.AUTHORITY_ENDPOINT;
    public static readonly REDIRECT_URI = environment.REDIRECT_URI;

    public static readonly SILENT_REDIRECT_URI = environment.SILENT_REDIRECT_URI;
    public static readonly AUTOMATIC_SILENT_RENEW = environment.AUTOMATIC_SILENT_RENEW;
    public static readonly POST_LOGOUT_REDIRECT_URI = environment.POST_LOGOUT_REDIRECT_URI;

    public static readonly SCOPE = environment.SCOPE;
  // static LEAP_ANG_ENDPOINT: string; // MIGHT BE REDUNDANT
}