import { Component, Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { ActivatedRoute, Router, NavigationEnd, NavigationStart } from '@angular/router';
import * as $ from 'jquery';
import { config } from './config';
import { from, of, Subscription } from 'rxjs';
import { SplashScreenService } from './theme/core/splash-screen/splash-screen.service';
import { LoginResponse, OidcSecurityService, PublicEventsService } from 'angular-auth-oidc-client';
import { AuthenticationModeHelper } from '@isb-technologies/authentication/developer/authentication-mode.helper';
import { AuthyService } from '@isb-technologies/authentication/auth/authy.service';
import { map, switchMap, tap } from 'rxjs/operators';
import { ProfileBySubjectIdGQL } from '@isb-technologies/authentication/auth/profile-by-subject-id.query';
import { Profile } from '@isb-technologies/authentication/models/profile.model';
import { NgxPermissionsService } from 'ngx-permissions';
import { CreateProfileMutationGQL } from '@isb-technologies/authentication/graphql/create-profile.mutation';
import { Mutation } from 'apollo-angular';
import { AccountProfileResult } from '@isb-technologies/authentication/authorisation/account-profile-result.model';
import { MyLoginResponse } from '@isb-technologies/authentication/my-login-response.model';
import { EventsListener } from '@isb-technologies/authentication/events/events-listener.component';
import { SignalRService } from '@isb-technologies/signalr/_services/signal-r.service';
declare let gtag: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent extends EventsListener {

  private unsubscribe: Subscription[] = []; // Read more: => https://brianflove.com/2016/12/11/anguar-2-unsubscribe-observables/

  title = 'kinzi-ng-node';
  // isBrowser: boolean = false;

  public _appMutationInstance: Mutation;

  constructor(
    @Inject(PLATFORM_ID) platformId: any,
    private router: Router, private activatedRoute: ActivatedRoute,
    private splashScreenService: SplashScreenService,
    public oidcSecurityService: OidcSecurityService,
    // private spinner: NgxSpinnerService
    public _auth: AuthenticationModeHelper,
    private _profileQueryBySubjectId: ProfileBySubjectIdGQL,
    private createProfileMutation: CreateProfileMutationGQL,
    public _authy: AuthyService,
    private _ngxPermissions: NgxPermissionsService,
    eventService: PublicEventsService,
    public signalRService: SignalRService,
  ) {
    super(platformId, eventService)
    // //debugger;
    // this.isBrowser = isPlatformBrowser(platformId);
    this._appMutationInstance = createProfileMutation;

  }

  ngOnInit() {


    //  localStorage.removeItem("0-knockknockpublic");

    // //debugger;
    this.isBrowser = isPlatformBrowser(this.platformId);

    let checkAuth$ = this._authy.checkAuth()
      .pipe(
        tap((loginResponse) => {
          // this._auth.next(loginResponse?.isAuthenticated || false);
        }),
        map((loginResponse) => {
          return new MyLoginResponse(null, loginResponse);
        }),
             
        switchMap((loginResponse: MyLoginResponse) => {
          if (loginResponse?.loginResponse?.isAuthenticated) {
            let redirectUrl = "/";

            let subjectId = loginResponse?.loginResponse?.userData.sub;

            let profile$ = this.onProfileCheck(subjectId, redirectUrl)
              .pipe(
                map((_: Profile) => {
                  return new MyLoginResponse(_.id, loginResponse?.loginResponse);
                })
              )
            return profile$;
          }
          else {
            return of(loginResponse);
          }
        }),
         /** 
         * ? 09/05/2022 - Added because SignalR Connection is needed to start, when a User is known to be authenticated 
         **/
        tap((loginResponse : MyLoginResponse) => {
          if (loginResponse?.loginResponse?.isAuthenticated) {
            // //debugger;
            this.signalRService.startHubConnection();
          }        
        }),
        switchMap((loginResponse: MyLoginResponse) => {

          if (loginResponse.loginResponse.isAuthenticated && !loginResponse.id) {
            var userData = loginResponse?.loginResponse?.userData;

            // //debugger;
            var MUT_VARS = {
              item: {
                subjectId: userData.sub,
                firstName: userData.given_name,
                lastName: userData.family_name,
                email: userData.name // Email
              }
            };

            return this.appMutationImpl(MUT_VARS)
              .pipe(
                map((_: Profile) => {
                  return new MyLoginResponse(_.id, loginResponse?.loginResponse);
                })
              )
          } else {
            return of(loginResponse);
          }
        }),
        tap((loginResponse) => {
          this._auth.next(loginResponse?.loginResponse?.isAuthenticated || false);
        }),
        switchMap((loginResponse) => {
          return this._auth.account();
        }),
        tap((session) => {
          // //debugger;
          if (session?.account?.accountTypeCode == 'MAINOFFICE') {

            this.router.navigate(['/profile', session.account.id, 'wizard', 1]);

          }
        })
      )


      .subscribe((_) => {
        // //debugger;
        // console.log(_);

        // if(_.id) {
        // this._auth.next(true);
        // }
      });

    this.subscriptions$.push(checkAuth$);

    // .subscribe((isAuthenticated) => {
    //   // //debugger; 
    //   this._auth.next(isAuthenticated.isAuthenticated);

    //   if (isAuthenticated.userData) {
    //     //       // self.router.navigate(['/profile', `${subjectId}`,'authorise']);

    //     //       ////debugger

    //     //     } else {
    //     //       ////debugger
    //     //       self.router.navigateByUrl(redirectUrl);
    //     //     }
    //   }

    //   console.log('is authenticated', isAuthenticated)
    // });

    //   this.oidcSecurityService.checkAuth().subscribe((isAuthenticated: LoginResponse) => {
    //     console.log('is authenticated', isAuthenticated)

    //     this._auth.next(isAuthenticated.isAuthenticated);

    // });

    //   this._authy.checkAuth().subscribe((isAuthenticated: LoginResponse) => {
    //     console.log('is authenticated', isAuthenticated)

    //     // this._auth.next(isAuthenticated.isAuthenticated);

    // });

    // this.router.events.subscribe((evt) => {
    //   if (!(evt instanceof NavigationEnd)) {
    //     return;
    //   }
    //   if (this.isBrowser) window.scrollTo(0, 0);      
    // });
    // this.router.events.subscribe((url: NavigationEnd) => {
    //   if (url && url.urlAfterRedirects) {
    //     if (this.isBrowser) {
    //       gtag('config', config.googleAnalyticsId,
    //         {
    //           'page_path': url.urlAfterRedirects
    //         }
    //       );
    //     }        
    //   }
    //   return;
    // });

    // const routerSubscription = this.router.events.subscribe((event) => {
    //   if (event instanceof NavigationEnd) {
    //     // hide splash screen
    //     this.splashScreenService.hide();

    //     if(this.isBrowser) {
    //     // scroll to top on every route change
    //       window.scrollTo(0, 0);


    //     // to display back the body content
    //     setTimeout(() => {
    //       document.body.classList.add('page-loaded');
    //     }, 500);
    //     }
    //   }
    // });
    // this.unsubscribe.push(routerSubscription);
  }


  ngOnDestroy() {
    this.unsubscribe.forEach((sb) => sb.unsubscribe());

    // //debugger;
    this.signalRService?.closeHubConnection();
  }


  appMutationImpl(vars: any) {

    // //debugger;
    let mutationGQL = this._appMutationInstance;
    let mutation$ = mutationGQL.mutate(vars);

    let o$ = mutation$;
    let p$ = o$.pipe(

      map(result => {
        const item = (<any>result).data;
        const systemAccountProfile = item ? (<any>item).createAccountProfile.data as Profile : null;

        return {
          systemAccountProfile: systemAccountProfile
        } as AccountProfileResult;
      }),
      map((accountProfileResult: AccountProfileResult) => {
        return accountProfileResult.systemAccountProfile;
      })
    );
    return p$;
  }

  private __MUT_VARS() { }

  onProfileCheck(subjectId: string, redirectUrl: string) {
    // this._loadingService.showLoading('Please wait...');

    ////debugger
    let vars = {
      subjectId: subjectId,
      assignedOnly: true
    };

    return this._profileQueryBySubjectId
      // .watch(vars)
      // .valueChanges
      .fetch(vars)
      .pipe(map(result => {

        var item = (<any>result.data);
        return (<any>item).accountProfileBySubjectId.data as Profile;
      }),

        tap((item: any) => {
          //this.item = item as Profile;

          if (item.id) {


            var userRole = item.roles;
            var permissions = [];
            ////debugger
            if (userRole)
              if (userRole[0]) {
                console.log(userRole[0]);
                if (userRole[0].permissions && userRole[0].assigned)
                  // if(userRole[0].appRole.appRolePermissions) {
                  userRole[0].permissions.map((elem, i) => {
                    if (elem.assigned === true) {
                      permissions.push(elem.name);
                    }
                  })
                // }
              }


            // let identityRole = '';
            // if(identityRole = this.service.currentUser.profile.role) {
            //   this._dev.isDeveloper.next(true);
            //   permissions.push(identityRole);
            // } else {
            //   this._dev.isDeveloper.next(false);
            //   localStorage.removeItem(DeveloperConstants.IS_DEVELOPER_MODE);
            // }



            localStorage.setItem("permissions", JSON.stringify(permissions));
            this._ngxPermissions.loadPermissions(permissions);

            // this.router.navigateByUrl(redirectUrl);
          } else {
            // this.router.navigate(['/profile', `${subjectId}`, 'authorise']);
            // this._loadingService.hideLoading();
          }
        }));
    // let vars = {
    //   subjectId: subjectId
    // };

    // this._profileService.getProfileBySubjectIdQuery(subjectId)
    //   .pipe(map(result => {

    //     var item = (<any>result);
    //     return (<any>item) as Profile;
    //   })
    //   ).subscribe((item: any) => {
    //     //this.item = item as Profile;

    //     if (item.id) {
    //       // this.service.appProf = item;
    //       this.service.setApplicationProfileUser(item);

    //       var userRole = item.appUserRoles;
    //       var permissions = [];
    //       if(userRole)
    //               if(userRole[0]) {
    //                 if(userRole[0].appRole) 
    //                 if(userRole[0].appRole.appRolePermissions) {
    //                   userRole[0].appRole.appRolePermissions.map((elem, i) => {
    //                       permissions.push(elem.appPermission.claimValue);
    //                     }) 
    //                 }
    //               }

    //               let identityRole = 'Developer';
    //               if(identityRole = this.service.currentUser.profile.role) {
    //                 this._dev.isDeveloper.next(true);
    //                 //localStorage.setItem(DeveloperConstants.IS_DEVELOPER_MODE, JSON.stringify(false));
    //                 permissions.push(identityRole);
    //               } else {
    //                 this._dev.isDeveloper.next(false);
    //                 localStorage.removeItem(DeveloperConstants.IS_DEVELOPER_MODE);
    //                 // localStorage.setItem(DeveloperConstants.IS_DEVELOPER_MODE, JSON.stringify(false));
    //               }

    //               localStorage.setItem("permissions", JSON.stringify(permissions));
    //              this._permissions.loadPermissions(permissions);

    //       this.router.navigateByUrl(redirectUrl);
    //     } else {
    //       this.router.navigate(['/profile', `${subjectId}`, 'authorise']);
    //       // this._loadingService.hideLoading();
    //     }
    //   });
  }
}
