import { Injectable } from '@angular/core';

import { HubConnectionBuilder, HubConnection, MessageType } from '@aspnet/signalr';
import { AppSettings } from '@isb-technologies/app.settings';
import { APP_CONSTANTS } from '@isb-technologies/APP_CONSTANTS';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { Subject } from 'rxjs';
import { SignalRConstants } from '../_constants/signalr.constants';

import $ from "jquery";

export enum SignalRMessageType {
  Generic = 0,
  ClosedOnError = 1,
  ClientDisconnected = 2,
  NoInternetConnection = 3,
  Fatal = 3,
}

@Injectable()
export class SignalRService {

  public currentConnection: Subject<SignalRMessageType> = new Subject();

  private notificationsApiEndPoint: string = `${AppSettings.NOTIFICATIONS_API_ENDPOINT}`;

  private _hubConnection: HubConnection;
  private _connected: boolean;
  private _isConnecting: boolean;
  private _connectionId: string;

  get hubConnection() { return this._hubConnection; }
  get isHubConnected() { return this._connected; }
  get isHubConnecting() { return this._isConnecting; }
  get connectionId() { return this._connectionId; }

  constructor(
    // private _swalService: SwalService,
    // private _toastr: ToastrService,
    // private authService: AuthyService,
    public oidcSecurityService: OidcSecurityService
  ) {
    this._connected = false;
    this._isConnecting = false;

    
  }

  configureHub() {
    this._connectionId = APP_CONSTANTS.EMPTY_GUID;
    //let transportType = TransportType[this.getParameterByName('transport')] || TransportType.WebSockets;
    //let logger = new ConsoleLogger(LogLevel.Information);

    //debugger;
    
    const token = this.oidcSecurityService.getAccessToken();

    this.currentConnection.subscribe((messageType: SignalRMessageType) => {
      switch (messageType) {
        case SignalRMessageType.Generic: {
          this.startHubConnection();
          break;
        }
        case SignalRMessageType.ClosedOnError: {
          this.startHubConnection();
          break;
        }
        case SignalRMessageType.ClientDisconnected: {
          this.startHubConnection();
          break;
        }
        case SignalRMessageType.Fatal: {
          this.startHubConnection();
          break;
        }
        default: this.startHubConnection();
      }
    });

    this._hubConnection = new HubConnectionBuilder()
      .withUrl(this.notificationsApiEndPoint, { accessTokenFactory: () => token })
      .build();

    this.hubConnection.onclose((error: Error) => {
      if (error) {
        
        // Connection closed because of error!
        console.log(error);
        this._connected = false;
        this._isConnecting = false;

        // this._toastr.addInfoMessage("An error occured and you were disconnected from the server.")

        var message = '<p>An error occured and you were disconnected from the server. Please click <strong>Refresh</strong> button to re-connect to the services.</p><p>If problem persists please contact <strong>Support</strong> on <strong><a href="mailto:" target="_top"></a></strong></p>';
        // this._swalService.reconnectDialog(message).then(
        //   (result) => {
        //     if (result.value) {
        //       this.currentConnection.next(SignalRMessageType.Fatal);
        //     }
        //   }
        // );
      } else {
        // Disconnected
        this._connected = false;
        this._isConnecting = false;
        // this._toastr.addInfoMessage("Disconnected.");

        var message = "<p>You application has disconnected. Please check your internet connection"
          + "and then click the <strong>Refresh</strong> button below to try and re-connect to the services.</p>";

        // this._swalService.reconnectDialog(message).then(
        //   (result) => {
        //     if (result.value) {
        //       this.currentConnection.next(SignalRMessageType.ClientDisconnected);
        //     }
        //   }
        // );

        
        console.log('signalR disconnected');
      }
    });

    this.hubConnection.on(SignalRConstants.SESSION_STARTED, this.onSessionStarted);
    this.hubConnection.on(SignalRConstants.AUTH_ID_TEST, this.onAuthIdTest);
  }

  onSessionStarted = (connectionId: string) => {
    console.log("Connection ID: " + connectionId);
    this._connectionId = connectionId;

    // let topbarSignalRIcon = $('#m_topbar_signalr_icon');
    // (<any>topbarSignalRIcon).find('.m-nav__link-icon').addClass(['m-animate-shake']);

    // setTimeout(function () {
      // topbarSignalRIcon.find('.m-nav__link-icon').removeClass(['m-animate-shake']);
    // }, 2000);
  }

  onAuthIdTest = (userId: string) => {
    // // //debugger;
    console.log("User ID: " + userId);
   
    // alert(`Hello from Authentication Context - User Id: ${userId}`);
    
  }

  startHubConnection() {

    this.configureHub();
    
    if (!this._isConnecting) {
      // let topbarSignalRIcon = $('#m_topbar_signalr_icon');
      // (<any>topbarSignalRIcon).find('.m-nav__link-icon').addClass(['m-animate-blink']);
      this._isConnecting = true;
      this.hubConnection.start()
        .then(() => {
          
          console.log('Hub connection started')
          this._connected = true;
          this._isConnecting = false;
          
          // let topbarSignalRIcon = $('#m_topbar_signalr_icon');
          // (<any>topbarSignalRIcon).find('.m-nav__link-icon').removeClass(['m-animate-blink']);

        })
        .catch(err => {
          
          console.log('Error while establishing connection')
          this._connected = false;
          this._isConnecting = false;

          var message = '<p>It seems that the application encountered a problem while connecting to the services. Please click <strong>Refresh</strong> to re-connect to the services.</p><p>If problem persists please contact <strong>Support</strong> on <strong><a href="mailto:support@hr.com.mt" target="_top">support@hr.com.mt</a></strong></p>';
          
          // this._swalService.reconnectDialog(message).then(
          //   (result) => {
          //     if (result.value) {
          //       this.currentConnection.next(SignalRMessageType.NoInternetConnection);
          //     }
          //   }
          // );
        }
        );
    }
  }

  closeHubConnection() {
    if (!this._isConnecting) {
      this._isConnecting = false;
      this.hubConnection?.stop()
        .then(() => {
          console.log('Hub connection stopped')
          this._connected = false;
          this._isConnecting = false;
        })
        .catch(err => {
          
          console.log('Error while disconnecting connection')
          this._connected = false;
          this._isConnecting = false;
        }
        );
    }
  }

  getParameterByName(name, url = null) {
    if (!url) {
      url = window.location.href;
    }
    name = name.replace(/[\[\]]/g, "\\$&");
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
      results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, " "));
  }
}