
    <h1 id="waiting">Renewing...</h1>
    <div id="error"></div>

    <!-- PKCE -->
    <!-- <script>
        window.onload = function () {
          /* The parent window hosts the Angular application */
          const parent = window.parent;
          /* Send the id_token information to the oidc message handler */
          const event = new CustomEvent('oidc-silent-renew-message', { detail: window.location });
          parent.dispatchEvent(event);
        };
        </script> -->

        <!-- IMPLICIT - IFRAME -->

        <script>
            window.onload = function () {
              /* The parent window hosts the Angular application */
              const parent = window.parent;
              /* Send the id_token information to the oidc message handler */
              const event = new CustomEvent('oidc-silent-renew-message', { detail: window.location.hash.substr(1) });
              parent.dispatchEvent(event);
            };
            </script>
    
    <!--<script src="../../assets/oidc-client.min.js"></script>
    <script>
        //////debugger
        Log.logger = console;
        //////debugger
        new UserManager().signinRedirectCallback().then(function (user) {
            if (user == null) {
                document.getElementById("waiting").style.display = "none";
                document.getElementById("error").innerText = "No sign-in request pending.";
            }
            else {
                window.location = "/";
            }
        })
        .catch(function (er) {
            document.getElementById("waiting").style.display = "none";
            document.getElementById("error").innerText = er.message;
        });
    </script>-->
