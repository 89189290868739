import { Injectable } from "@angular/core";
import { AuthenticatedResult, LoginResponse, OidcSecurityService } from "angular-auth-oidc-client";
import { ReplaySubject, Observable, BehaviorSubject } from "rxjs";
import { debounce, debounceTime, first, last, map, switchMap, tap } from "rxjs/operators";
import { AuthenticationModeHelper } from "../developer/authentication-mode.helper";

@Injectable()
export class AuthyService {
    private checkAuthCompleted$ = new BehaviorSubject(false);

    constructor(private oidcSecurityService: OidcSecurityService, 
        private _authModeHelper: AuthenticationModeHelper) {}

    // ...

    public get isAuthenticated$(): Observable<boolean> {
        return this.checkAuthCompleted$.pipe(
            // debounceTime(200),
            // first(),
            switchMap((_) => 
            {
                return this.oidcSecurityService.isAuthenticated$;
            }),
            tap((_: AuthenticatedResult) => {
                this._authModeHelper.next(_.isAuthenticated || false);
            }),
            map((_ : AuthenticatedResult)=>{
                // //debugger;
                return  _.isAuthenticated;

            })
        );
    }

    public checkAuth(): Observable<LoginResponse> {
        return this.oidcSecurityService.checkAuth()//http://localhost:4201/auth", "0-knockknockpublic2")
        .pipe(
            tap((_ : LoginResponse) => 
        {
            // //debugger;
            this.checkAuthCompleted$.next(_.isAuthenticated)
        }));
    }

    // ...
}